<template>
  <with-side-navigations side="left">
    <template #sideNavigation>
      <object-types-sidebar />
    </template>
    <div class="flex justify-between pb-10">
      <div class="flex-grow">
        <div class="flex flex-wrap gap-2 justify-between mb-2">
          <div class="flex items-center">
            <breadcrumbs
              :path="[
                {
                  name: $t('object-types-view.heading'),
                  url: { name: 'GeneralObjectTypes' },
                },
              ]"
              class="mt-2 mb-1"
            ></breadcrumbs>
            <filter-toggler class="ml-4" />
          </div>
          <btn class="w-26" @click="$router.push({ name: 'AddObjectType' })">
            {{ $t('common.add') }}
          </btn>
        </div>
        <fetch-data
          v-slot="{ isLoading, pagination }"
          url="object-types"
          :params="routeQuery"
          :force-update="listUpdatesCounter"
          :debounce="FILTER_DEBOUNCE"
          @data-deliver="updateList"
        >
          <div class="container">
            <div v-if="isLoading || deletionInProgress" class="spinner-area">
              <icon-spinner
                class="spinner stroke-primary"
                width="64px"
                height="64px"
              />
            </div>
            <div
              v-if="objectTypesList.length"
              class="flex flex-wrap"
              :class="{ 'opacity-50': isLoading || deletionInProgress }"
            >
              <card
                v-for="objectType in objectTypesList"
                :key="objectType.id"
                :image="objectType.cdnImageUrl"
                :alt="
                  $t('object-types-view.object-type-alt', {
                    name: objectType.name,
                  })
                "
                :context-menu="{
                  edit: {
                    name: $t('common.edit'),
                    handler: () => {
                      onEdit(objectType.id);
                    },
                  },
                  delete: {
                    name: $t('common.delete'),
                    modal: {
                      type: 'confirm',
                      title: $t('object-types-view.modal-title'),
                      description: $t('object-types-view.modal-description', {
                        type: objectType.name,
                      }),
                      btnText: $t('object-types-view.modal-btn'),
                    },
                    handler: () => {
                      onDelete(objectType.id);
                    },
                  },
                }"
                class="mt-4 mr-4"
                cover-img
              >
                {{ objectType.name }}
              </card>
            </div>
            <div class="flex w-full my-3 justify-center">
              <pagination
                v-if="objectTypesList.length"
                :page-count="lastPage"
                :current-page="routeQuery.page || 1"
                class="mt-2"
                @change-page="changePage"
              ></pagination>
            </div>
          </div>
          <entries-counter
            v-if="pagination"
            url="object-types"
            :to="pagination.to"
            :from="pagination.from"
            :filtered-entries="pagination.total"
          />
        </fetch-data>
      </div>
    </div>
  </with-side-navigations>
</template>

<script>
import Btn from '@/components/Button/Button.vue';
import Card from '@/components/Card/Card.vue';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
import ObjectTypesSidebar from '@/views/Dashboard/ObjectTypes/ObjectTypesSidebar.vue';
import FetchData from '@/components/DataProvider/FetchData.vue';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import EntriesCounter from '@/components/Table/EntriesCounter';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import { mapActions } from 'vuex';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import cardPagination from '@/mixins/query/cardPagination';

export default {
  name: 'ObjectTypes',
  components: {
    Btn,
    Card,
    WithSideNavigations,
    ObjectTypesSidebar,
    FetchData,
    IconSpinner,
    Pagination,
    Breadcrumbs,
    EntriesCounter,
    FilterToggler,
  },
  data() {
    return {
      objectTypesList: [],
      deletionInProgress: false,
      listUpdatesCounter: 0,
      lastPage: 0,
    };
  },
  mixins: [cardPagination],
  methods: {
    ...mapActions('object', ['deleteObjectType']),
    updateList(data) {
      this.objectTypesList = data.results;
      this.lastPage = data.pagination.lastPage;
    },
    onEdit(id) {
      this.$router.push({
        name: 'EditObjectType',
        params: { objectTypeId: id },
      });
    },
    async onDelete(id) {
      try {
        this.deletionInProgress = true;
        await this.deleteObjectType(id);
        this.listUpdatesCounter++;
        this.$toast.success(this.$t('object-types-view.deleted-successfully'));
      } finally {
        this.deletionInProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @apply flex flex-wrap relative;
}
.spinner-area {
  @apply py-48 w-full flex justify-center absolute z-50;
}
</style>
